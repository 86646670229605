import { RouterProvider } from 'react-router-dom';
import router from 'routes';
import ScrollTop from 'components/ScrollTop';
import ThemeCustomization from 'themes';
import SnackBarProvider from 'components/third-party/Notistack';
import Snackbar from 'components/@extended/Snackbar';

function App() {
  return (
    <ThemeCustomization>
      <ScrollTop>
        <SnackBarProvider>
          <RouterProvider router={router} />
          <Snackbar />
        </SnackBarProvider>
      </ScrollTop>
    </ThemeCustomization >
  );
}

export default App;
