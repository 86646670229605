import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import Error404 from 'pages/maintenance/404';

import { openSnackbar } from 'hooks/useGetSnackbar';
import { SnackbarProps } from 'types/snackbar';
import { useEffect } from 'react';
import AnimateButton from 'components/@extended/AnimateButton';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import error500 from 'assets/images/maintenance/Error500.png';
import { Typography } from '@mui/material';

const CustomErrorPage = () => (
  <Box display="flex" flexDirection="column" alignItems="center" py={4}>
    <Box sx={{ width: { xs: 350, sm: 396 }, mb: 3 }}>
      <img src={error500} alt="エラー画像" style={{ width: '100%', height: 'auto' }} />
    </Box>
    <Typography variant="h4" gutterBottom>
      エラーが発生しました
    </Typography>
    <Typography variant="body1" color="textSecondary" paragraph>
      予期しないエラーが発生しました。再度お試しください。
    </Typography>
    <AnimateButton>
      <Button
        disableElevation
        size="large"
        variant="contained"
        color="primary"
        onClick={() => window.location.reload()}
      >
        リロードする
      </Button>
    </AnimateButton>
  </Box>
);

const ErrorBoundary = () => {
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
          // 404 エラーの場合は Error404 を表示
          return;
        }
      }
      // 404 以外のエラーでは snackbar を表示
      openSnackbar({
        open: true,
        message: '予期しないエラーが発生しました',
        variant: 'alert',
        alert: {
          color: 'error'
        }
      } as SnackbarProps);
    }
  }, [error]);

  // 404 エラー時のみエラーページを表示
  if (isRouteErrorResponse(error) && error.status === 404) {
    return <Error404 />;
  }

  return <CustomErrorPage />;
};

export default ErrorBoundary;